import axios from "axios";
import { ElMessage } from "element-plus";
import router from "@/router";
import store from "@/store/index";

const ENGLISH_SERVICE_URL = "/api/english-service";
const CHINESE_SERVICE_URL = "/api/chinese-service";

const axiosInstance = axios.create();

axiosInstance.defaults.baseURL =
  store.state.currentOperationStage === "外贸"
    ? ENGLISH_SERVICE_URL
    : CHINESE_SERVICE_URL;
console.log(store.state.currentOperationStage);

export const setBaseURL = (newBaseURL) => {
    axiosInstance.defaults.baseURL = newBaseURL;
    console.log(`Switched baseURL to: ${newBaseURL}`);
};

export const getBaseURL = () => {
    return axiosInstance.defaults.baseURL;
};

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    if (err.response && err.response.status === 401) {
      ElMessage.warning("登录状态失效，请重新登录");
      localStorage.removeItem("token");
      router
        .push("/login")
        .then(() => {
          console.log("Redirected to login");
        })
        .catch((error) => {
          console.error("Failed to redirect:", error);
        });
    }
    return Promise.reject(err);
  }
);

export default axiosInstance;
